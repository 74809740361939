import React from 'react';
import './CaseStudyView.css';
import { useParams } from 'react-router';
import { CaseStudyList } from '../../utils/constants/CaseStudyList';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Card, Typography, Divider, Grid, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import appList from '../../utils/constants/AppList';
import CallToActionContactUs from '../../components/CallToActionContactUs/CallToActionContactUs';

function CaseStudyView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  if (!params.id) return null;
  const caseStudy = CaseStudyList.find((x) => x.id === params.id);
  if (!caseStudy) return null;

  let ids = [];
  switch (parseInt(caseStudy.id)) {
    case 1:
      ids = [7, 9, 10];
      break;
    case 7:
      ids = [1, 3, 5, 6, 9];
      break;
    default:
  }
  const apps = appList.filter((x) => ids.includes(x.id));

  return (
    <div>
      <Box
        className="case-study-view-header"
        sx={{ backgroundColor: '#f5f5f5', padding: '40px 0' }}
      >
        <Container maxWidth="lg">
          <div className="chip mb-3">Case Study</div>
          <Typography variant="h3">{caseStudy.title}</Typography>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        className="d-flex flex-column justify-content-center"
      >
        <img
          className="case-study-image"
          src={caseStudy.image}
          alt={caseStudy.caption}
          style={{ width: '100%', borderRadius: '8px', marginBottom: '20px' }}
        />

        {apps.length ? (
          <Box>
            <Grid container spacing={2} sx={{ padding: '10px 0' }}>
              {apps.map((x) => (
                <Grid item key={x.title}>
                  <Tooltip title={x.title}>
                    <Card
                      sx={{
                        background: '#fff',
                        borderRadius: '10px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 60,
                        height: 60,
                      }}
                      variant="outlined"
                    >
                      <img width={50} src={x.icon} alt={x.title} />
                    </Card>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
            <Box className="px-2 py-2 w-100 d-flex justify-content-end">
              <div className="chip blue">Developed With</div>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Container>

      <Box className="case-study-view-content" sx={{ padding: '40px 0' }}>
        <Container maxWidth="lg">
          <Box mb={6}>
            <Typography variant="h4" mb={2}>
              About the Client
            </Typography>
            <Divider />
            <Typography variant="body1" mt={3}>
              {caseStudy.clientBackground}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography variant="h4" mb={2}>
              The Challenge
            </Typography>
            <Divider />
            <Typography variant="body1" mt={3}>
              {caseStudy.challenges}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography variant="h4" mb={2}>
              The Solution
            </Typography>
            <Divider />
            <Typography variant="body1" mt={3}>
              {caseStudy.approach}
            </Typography>
            <Typography variant="body1" mt={3}>
              {caseStudy.solution}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" mb={2}>
              The Result
            </Typography>
            <Divider />
            <Typography variant="body1" mt={3}>
              {caseStudy.results}
            </Typography>
          </Box>
        </Container>
      </Box>
      <CallToActionContactUs />
    </div>
  );
}

export default CaseStudyView;
