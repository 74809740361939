export const ServiceType = {
  development: {
    id: 1,
    icon: 'development',
    label: 'Web Applications Development',
    description:
      'We create stunning and functional web applications using technologies like React, Node.js, and Angular. Our solutions enhance your online presence and drive engagement with your target audience.',
  },
  testing: {
    id: 2,
    icon: 'testing',
    label: 'Quality Assurance (QA) Testing',
    description:
      'We conduct thorough testing of your website or app to ensure its functionality, usability, and performance, providing a seamless and reliable experience for your users.',
  },
  SEO: {
    id: 3,
    icon: 'seo',
    label: 'Search Engine Optimization',
    description:
      'We enhance your website’s visibility on search engines through comprehensive SEO strategies, driving organic traffic and helping you reach a wider audience.',
  },
  accessibility: {
    id: 4,
    icon: 'accessibility',
    label: 'Accessibility Compliance Services',
    description:
      'We ensure your website is accessible to all users, including those with disabilities, by adhering to the latest accessibility standards and guidelines.',
  },
  support: {
    id: 5,
    icon: 'support',
    label: 'Customer Support & Services',
    description:
      'We provide reliable customer support to address inquiries, resolve issues, and ensure customer satisfaction, thereby enhancing your brand reputation.',
  },
  websites: {
    id: 6,
    icon: 'websites',
    label: 'Website Design & Development',
    description:
      'We design and develop professional, tailor-made websites using platforms and technologies like WordPress, Next.js, React, and Angular. Our solutions ensure a robust online presence and optimal user experience.',
  },
  digitalMarketing: {
    id: 7,
    icon: 'marketing',
    label: 'Digital Marketing',
    description:
      'We develop comprehensive digital marketing strategies to increase your brand’s online visibility, engage your target audience, and drive conversions.',
  },
};
