import './ContactUs.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  CardContent,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import EmailField from '../../components/EmailField/EmailField';
import Whatsapp from '../../assets/svg/whatsapp.svg';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleNameBlur = (event) => {
    setNameTouched(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        setEmail('');
        setNameTouched(false);
        setValidEmail(false);
        setEmailError(false);
        setEmailTouched(false);
        alert('Thank you! We will be reaching out soon.');
      })
      .catch((error) => alert(error));
  };

  return (
    <Box mt={17} mb={35}>
      <Container maxWidth="lg" className="contact-us-container">
        <div className="contact-us-container">
          <Box variant="outlined" className="contact-us-card">
            <CardContent>
              <form name="contact" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <Typography className="contact-us-subheader" variant="h6">
                  Get in Touch
                </Typography>
                <Typography variant="h4">
                  Let's Chat, Reach Out to Us
                </Typography>
                <Typography mb={3}>You can reach us anytime</Typography>
                <Divider />
                <Box
                  mt={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <TextField
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                    }}
                    className="filled-text-field"
                    label="Name"
                    required
                    id="name"
                    name="name"
                    type="text"
                    error={!formData.name && nameTouched}
                    variant="outlined"
                    value={formData.name}
                    onBlur={handleNameBlur}
                    onChange={handleChange}
                    helperText={
                      !formData.name && nameTouched ? 'Name is required.' : ''
                    }
                  />
                  <EmailField
                    email={email}
                    setEmail={setEmail}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    setValidEmail={setValidEmail}
                    emailTouched={emailTouched}
                    setEmailTouched={setEmailTouched}
                    onChange={handleChange}
                  />
                  <TextField
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                    }}
                    className="filled-text-field"
                    label="Message"
                    id="message"
                    name="message"
                    type="text"
                    variant="outlined"
                    multiline
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                  />

                  <Button
                    disabled={!formData.name || !email || !validEmail}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Box>
          <Box
            className="contact-us-card-contact-info"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <Box
              variant="outlined"
              className="contact-us-card contact-us-card-image"
            ></Box>
            <Box
              sx={{
                width: '100%',
                padding: '30px',
                borderRadius: '20px',
              }}
              variant="outlined"
            >
              <Box mb={2} className="contact-info-container">
                <FontAwesomeIcon
                  className="contact-info-container-icon"
                  color="#fff"
                  icon={faEnvelope}
                />
                <Box>
                  <Typography>Email</Typography>
                  <a
                    className="contactus-email"
                    href="mailto:ask@webfluid.studio?subject=Inquiry%20from%20Website:%20Seeking%20Webfluid%20Studio's%20Expert%20Services"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ask@webfluid.studio
                  </a>
                </Box>
              </Box>
              <Box mb={2} className="contact-info-container">
                <FontAwesomeIcon
                  className="contact-info-container-icon"
                  color="#fff"
                  icon={faPhone}
                />
                <Box>
                  <Typography>Phone</Typography>
                  <Typography>
                    <a
                      id="company-phone-number"
                      href="tel:786-856-2000"
                      rel="noreferrer"
                    >
                      786 856 2000
                    </a>
                  </Typography>
                </Box>
              </Box>
              <Box className="contact-info-container">
                <Box
                  id="whatsapp-icon-container"
                  className="contact-info-container-icon"
                >
                  <img id="whatsapp-icon" alt="Whatsapp logo" src={Whatsapp} />
                </Box>
                <Box>
                  <Typography>WhatsApp</Typography>
                  <Typography>
                    <a
                      id="whatsapp-link"
                      href="https://api.whatsapp.com/send?phone=7868562000"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Chat With Us
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Container>
    </Box>
  );
}

export default ContactUs;
