import './Service.css';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

function Service({ serviceType, inverted, color, isHero }) {
  const [showIcon, setShowIcon] = useState(true);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setShowIcon(window.innerWidth > 460);
    });
  }, [showIcon, setShowIcon]);

  return (
    <Box
      className={
        'service-container ' +
        (inverted ? 'service-container-blue' : '') +
        (isHero ? 'service-container-hero ' : '') +
        color
      }
    >
      {/* <Box className="service-label-wrapper d-flex align-items-center gap-3 mb-4">
        {showIcon ? (
          <FontAwesomeIcon
            color="white"
            size="2x"
            icon={getIcon(serviceType.icon)}
          />
        ) : (
          ''
        )}
        <Typography className="service-label" variant="h5" fontWeight={500}>
          {serviceType.label}
        </Typography>
      </Box> */}
      <Typography className="service-label" variant="h5" fontWeight={700}>
        {serviceType.label}
      </Typography>
      <Typography className="service-description" variant="h7">
        {serviceType.description}
      </Typography>
    </Box>
  );
}

export default Service;
