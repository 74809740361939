const generateEmployee = (id, name, position, avatar) => {
  return {
    id,
    name,
    position,
    avatar,
  };
};
export const employees = [
  generateEmployee(
    2,
    'Roman Rojas',
    'CTO & Lead Engineer',
    'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/c0/c0fe206a3e891e0ffddaf2e677e1be007f28c2d0_full.jpg'
  ),
  generateEmployee(
    1,
    'Marcio Rodrigues',
    'Software Engineer',
    'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/06/068a041fea494875be307f67934e3989db6cbf43_full.jpg'
  ),
  // generateEmployee(
  //   3,
  //   'Sophia Carter',
  //   'Project Manager',
  //   'https://akm-img-a-in.tosshub.com/sites/visualstory/stories/2023_10/story_68798/assets/1.png?time=1696920077'
  // ),
];
