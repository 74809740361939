import React from 'react';
import './DisplayWidget.css';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function DisplayWidget({ data, isHero, color, route, small }) {
  return (
    <Box
      component={Link}
      to={route ? route + (data.id ? '/' + data.id : '') : data.id}
      className={
        'display-widget ' +
        color +
        (small ? ' display-widget-small' : '') +
        (route ? ' display-widget-routable' : '')
      }
    >
      <Box className="display-widget-label">
        <Typography variant="h7" fontWeight={700}>
          {data.companyName}
        </Typography>
        <Typography variant="h8" fontWeight={600}>
          {data.year}
        </Typography>
      </Box>
      {/* {small ? '' : <Typography variant="h5">{data.title}</Typography>} */}
    </Box>
  );
}

export default DisplayWidget;
