const appList = [
  {
    id: 1,
    icon: 'https://images.webfluid.studio/node.png',
    title: 'Node App',
    version: 'v20.13.1',
  },
  {
    id: 2,
    icon: 'https://images.webfluid.studio/wordpress.png',
    title: 'WordPress',
    version: 'v6.5',
  },
  {
    id: 3,
    icon: 'https://images.webfluid.studio/react.png',
    title: 'React Project',
    version: 'v18.2.0',
  },
  {
    id: 4,
    icon: 'https://images.webfluid.studio/angular.png',
    title: 'Angular',
    version: 'v18.0',
  },
  {
    id: 5,
    icon: 'https://images.webfluid.studio/vitejs.svg',
    title: 'viteJS',
    version: '',
  },
  {
    id: 6,
    icon: 'https://images.webfluid.studio/docker.png',
    title: 'Docker',
    version: '',
  },
  {
    id: 7,
    icon: 'https://images.webfluid.studio/nextjs.png',
    title: 'NextJS',
    version: '',
  },
  {
    id: 8,
    icon: 'https://images.webfluid.studio/less.webp',
    title: 'Less',
    version: '',
  },
  {
    id: 9,
    icon: 'https://images.webfluid.studio/tailwind.png',
    title: 'Tailwind CSS',
    version: '',
  },
  {
    id: 10,
    icon: 'https://images.webfluid.studio/vercel.jpg',
    title: 'Vercel',
    version: '',
  },
];

export default appList;
