import React from 'react';
import './EmployeeDesciption.css';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

function EmployeeDesciption({ employee }) {
  let avatar = '';
  switch (employee.id) {
    case 1:
      avatar = 'https://images.webfluid.studio/marcio.jfif';
      break;
    case 2:
      avatar = 'https://images.webfluid.studio/roman.jpg';
      break;
    case 3:
      avatar = 'https://images.webfluid.studio/Female.jpg';
      break;
    default:
      avatar = employee.avatar;
  }
  return (
    <Box className="d-flex flex-column align-items-center">
      <Box className="avatar-container">
        <img className="avatar mb-3" src={avatar} alt={employee.name} />
      </Box>
      <Typography variant="h5">{employee.name}</Typography>
      <Typography className="employee-description-sub" variant="subtitle1">
        {employee.position}
      </Typography>
    </Box>
  );
}
export default EmployeeDesciption;
